<template>
  <dl class="grid gap-4">
    <div
      v-for="({ label, value, tooltip }, index) in table"
      :key="label"
      class="flex items-center gap-2 justify-between font-semibold leading-none"
      :class="{
        'rounded-sm bg-gray-50 ring-[6px] ring-gray-50': index % 2 !== 0,
      }"
    >
      <dt v-tooltip="tooltips ? tooltip : undefined" class="text-sm">
        {{ label }}
        <span
          v-if="descriptions"
          class="block text-subtle text-xs balance font-normal"
          >{{ tooltip }}</span
        >
        <app-icon
          v-if="tooltips"
          class="text-subtle"
          icon="ph:info"
          :size="14"
        />
      </dt>
      <dd class="tabular-nums">{{ value }}</dd>
    </div>
  </dl>
</template>

<script lang="ts" setup>
type DetailFields = Pick<
  DatabaseTable<"course_session_ratings">,
  "theory" | "skills" | "experience" | "format" | "pedagogy" | "expertise"
>;

const KEYS_TO_DISPLAY = new Set<keyof DetailFields>([
  "expertise",
  "pedagogy",
  "experience",
  "format",
  "skills",
  "theory",
]);

const properties = withDefaults(
  defineProps<{
    details: DetailFields;
    exclude?: Array<keyof DetailFields>;
    descriptions?: boolean;
    tooltips?: boolean;
  }>(),
  {
    exclude: () => [],
  },
);

const { t } = useI18n();

function toDetailTableLine(key: keyof DetailFields, value: number) {
  return {
    label: t(`rating.details.${key}`),
    tooltip: t(`rating.details.${key}_tooltip`),
    value: useI18nNumber(value).value,
  };
}

const table = computed(() => {
  return [...KEYS_TO_DISPLAY]
    .filter((key) => !properties.exclude.includes(key as keyof DetailFields))
    .map((key) =>
      toDetailTableLine(key as keyof DetailFields, properties.details[key]),
    );
});
</script>
